import React from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import PaymentForm from "../../Components/Payments/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const Payments = () => {
  const stripePromise = loadStripe(
    "pk_live_51N1GYIDiR7LHJjoysaogCyzHOxnLImpYV3oyqZTUInzPdsRKX3nYPcemOMBlcGbv1iiCwrW3E01RfoVgw4bsasHs00aH2JaOuB"
  );

  return (
    <React.Fragment>
      <div className="bg-white">
        <Breadcrumbs pageTitle="Payments" title="Payment Profile" />
      </div>
      <div  className="flex justify-center w-full">
        <div className="mt-5 w-full sm:w-3/4">
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
              <Elements stripe={stripePromise}>
                <PaymentForm />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payments;
